var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('h6', {
    staticClass: "tit tit--xs font-secondary primary--text line-height-1 mb-8px mb-md-16px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(_vm._s(_vm.index))]), _c('p', {
    staticClass: "txt txt--lg txt--dark",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "400"
    }
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }