<template>
    <li class="mt-20px mt-md-40px">
        <h6 data-aos="fade-up" data-aos-delay="200" class="tit tit--xs font-secondary primary--text line-height-1 mb-8px mb-md-16px">{{index}}</h6>
        <p data-aos="fade-up" data-aos-delay="400" class="txt txt--lg txt--dark">
            <slot />
        </p>
    </li>
</template>

<script>
export default {
    props: {
        index: { type: String, default: "" },
    },
}
</script>

<style>

</style>