<template>
    <client-page class="app--client">
        <template #contentsImmersive>
            <div class="fixed-area">
                <div class="fixed-area__inner">
                    <div class="fixed-area__bg"></div>
                    <div class="fixed-area__ani">
                        <v-img :src="'/images/sub/products/'+category+'/text-ani2.svg'" contain class="fixed-area__ani-img fixed-area__ani-img--2"/>
                        <v-img :src="'/images/sub/products/'+category+'/text-ani3.svg'" contain class="fixed-area__ani-img fixed-area__ani-img--3"/>
                        <v-img :src="'/images/sub/products/'+category+'/text-ani.svg'" contain class="fixed-area__ani-img fixed-area__ani-img--1" />
                        <v-img :src="'/images/sub/products/'+category+'/text-ani4.svg'" contain class="fixed-area__ani-img fixed-area__ani-img--4"/>
                    </div>
                </div>
            </div>
            <div class="product-contents">
                <slot />
            </div>
        </template>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        PageSection,
    },
    props: {
        category: { type: String },
    },
    data() {
        return {
        }
    },
    mounted() {
        this.init();
    },
	methods : {
		init : function() {            
            
            let mobileWidth = 768;
            let offsetY = 300;
            if (window.innerWidth < mobileWidth) {
                offsetY = 180;
            }

            setTimeout(() => {
                AOS.init({
                    once: true,
                    offset: offsetY,
                    duration: 500,
                    throttleDelay : 100,
                    easing : 'ease-out',
                    anchorPlacement : 'top-center',
                });
            }, 100);
		}
	},
}
</script>

<style lang="scss" scoped>
.fixed-area{  

    --ci-width: 300px;
    --ci-height: 44px; 

    width: 100%;
    height: 100vh;
    padding-top: var(--header-body-height);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    animation: textAni5 .5s 6.5s ease-out forwards;
    &__inner{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__bg{
        position: absolute;
        width: 100%;
        height: 100vh;
        bottom: 0;
        left: 0;
        background-color: #fff;
    }
    &__ani{
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        animation: textAni2 1s .5s linear forwards;
        &-img{
            width: var(--ci-width);
            height: var(--ci-height);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            &--1{
                overflow: hidden;
                ::v-deep{
                    .v-image__image{
                        background-size: var(--ci-width) var(--ci-height);
                        background-position: center top !important;
                        animation: textAni1 1.2s 1.5s ease-out forwards;
                    }
                }
            }
            &--2{
                animation: textAni3 6s 2.7s ease-in forwards;
            }
            &--3{
                animation: textAni3 4s 2.7s ease-in forwards;
            }
            &--4{
                opacity: 0;
                visibility: hidden;
                animation: textAni4 4s 2.7s ease-in forwards;
            }
        }
    }
}
.product-contents{
    position: fixed;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    padding-top: var(--header-body-height);
    background-color: #fff;
    animation: textAni7 1s 7s ease-out forwards;
}
@keyframes textAni1{
    0%{
        max-height: var(--ci-height);
    }
    100%{
        max-height: 0;
    }
}
@keyframes textAni2{
    0%{
        opacity: 0;
        visibility: hidden;
    }
    100%{
        opacity: 1;
        visibility: visible;
    }
}
@keyframes textAni3{
    0%{
        transform: translate(-50%, -50%) scale(1);
    }
    30%{
        opacity: 1;
        visibility: visible;
        filter: blur(0);
    }
    100%{
        opacity: 0;
        visibility: hidden;
        filter: blur(20px);
        transform: translate(-50%, -50%) scale(5);
    }
}
@keyframes textAni4{
    0%{
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%) scale(1);
    }
    20%{
        opacity: 1;
        visibility: visible;
    }
    30%{
        opacity: 1;
        visibility: visible;        
        filter: blur(0);
    }
    100%{
        opacity: 0;
        visibility: hidden;
        filter: blur(20px);
        transform: translate(-50%, -50%) scale(5);
    }
}
@keyframes textAni5{
    0%{
        opacity: 1;
        visibility: visible;
    }
    99%{
        opacity: 0;
        visibility: hidden;
        display: block;
        z-index: 100;
    }
    100%{
        display: none;
        z-index: -1;
    }
}
@keyframes textAni7{
    0%{
        position: fixed;
    }
    100%{
        position: relative;
    }
}
@media (min-width:576px){
    .fixed-area{  
        --ci-width: 400px;
        --ci-height: 58px; 
    }
}
@media (min-width:768px){
    .fixed-area{  
        --ci-width: 620px;
        --ci-height: 90px; 
    }
}
@media (min-width:1024px){
    .fixed-area{      
        --ci-width: 792px;
        --ci-height: 116px; 
    }
}
@media (min-width:1200px){
}

</style>