<template>
    <products-layout category="tissue">
        <section class="tissue-section--1">
            <v-img src="/images/sub/products/tissue/tissue-bg.svg" :max-width="1040" :aspect-ratio="1040 / 1360" class="tissue-section--1__bg" />
            <div class="tissue-section--1__inner">
                <v-container>
                    <v-row class="tissue-section--1__row row--sm">
                        <v-col cols="12" class="tissue-section--1__row-title">
                            <h2 class="tit tit--xl overflow-hidden">
                                <span data-aos="fade-up" data-aos-delay="7200" class="d-block">브라이트 임플란트</span>
                            </h2>
                            <div class="overflow-hidden mt-12px mt-md-24px">
                                <v-img data-aos="fade-up" data-aos-delay="7400" src="/images/tissue-ci.svg" :max-width="$vuetify.breakpoint.mdAndUp ? 556 : 398" :aspect-ratio="556 / 84" contain  />
                            </div>
                        </v-col>
                        <v-col cols="12" md="10" offset-md="2" class="tissue-section--1__row-bg">
                            <v-img data-aos="zoom-out" data-aos-delay="7000" src="/images/sub/products/tissue/tissue-img.png" max-width="1196" :aspect-ratio="1196 / 1018" class="w-100 ml-auto" />
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </section>
        <section class="tissue-section--2">
            <v-img src="/images/sub/products/tissue/tissue-bg2.jpg" max-width="1920" :aspect-ratio="1920 / 1308" class="tissue-section--2__bg" />
            <div class="tissue-section--2__inner">
                <v-container>

                    <v-row justify="center" align="center">
                        <v-col cols="6" md="4">
                            <v-img data-aos="zoom-out" data-aos-delay="200" src="/images/sub/products/tissue/tissue-img2.png" max-width="348" :aspect-ratio="348 / 1138" class="ml-md-auto" />    
                        </v-col>
                        <v-col cols="12" md="8" class="mt-30px mt-md-0">
                            <div data-aos="fade-right" class="pl-md-20px pl-lg-114px tit font-weight-bold break-keep">
                                <div v-for="(detail, index) in info" :key="index" :class="index !== 0 ? 'mt-16px mt-md-32px' : ''">
                                    <v-row no-gutters>
                                        <v-col cols="auto">
                                            ·&nbsp;
                                        </v-col>
                                        <v-col>
                                            {{detail}}
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </section>    
        <section class="tissue-section--3">
            <div class="tissue-section--3__inner">
                <v-container>
                    <v-card data-aos="fade-up" tile color="grey darken-4" class="text-center">
                        <div class="pa-16px pa-md-28px">
                            <h3 class="tit white--text line-height-1">20º External Taper Fit</h3>
                        </div>
                    </v-card>
                    <div class="py-xl-40px mt-40px mt-md-80px">
                        <v-row align="center" justify="center" style="position: relative;">
                            <v-col cols="12" xl="4" class="tissue-section--3__img mb-24px mb-md-56px mb-xl-0">
                                <v-img data-aos="zoom-out" src="/images/sub/products/tissue/tissue-img3.png" max-width="430" :aspect-ratio="430 / 514" contain class="mx-auto" />
                            </v-col>
                            <v-col cols="12" md="6" class="text-md-right">
                                <v-card data-aos="fade-left" tile color="#f5eae9">
                                    <div class="pa-20px pa-md-30px pa-lg-40px">
                                        <div class="pr-xl-234px">
                                            <h3 class="tit line-height-1 red--text">
                                                External Hex
                                            </h3>
                                            <p class="font-size-20 font-size-md-26 mt-12px mt-md-24px">- Easy to connect</p>
                                        </div>
                                    </div>
                                </v-card>
                                <v-card data-aos="fade-left" data-aos-delay="200" tile color="#f5eae9" class="mt-30px mt-md-68px">
                                    <div class="pa-20px pa-md-30px pa-lg-40px">
                                        <div class="pr-xl-234px">
                                            <h3 class="tit line-height-1 red--text">
                                                Slim Nect Dimension
                                            </h3>
                                            <p class="font-size-20 font-size-md-26 mt-12px mt-md-24px">- Maximal marginal bone protection</p>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6" class="mt-14px mt-md-0">
                                <v-card data-aos="fade-right" data-aos-delay="300" tile color="#f5eae9">
                                    <div class="pa-20px pa-md-30px pa-lg-40px">
                                        <div class="pl-xl-234px">
                                            <h3 class="tit line-height-1 red--text">
                                                Taper Fit
                                            </h3>
                                            <p class="font-size-20 font-size-md-26 mt-12px mt-md-24px">- External Conical Seal Effect</p>
                                            <p class="font-size-20 font-size-md-26 mt-8px mt-md-16px">- High Flexibility and Strength</p>
                                            <p class="font-size-20 font-size-md-26 mt-8px mt-md-16px">- Easy Handling for Multiple Prosthetic</p>
                                        </div>
                                    </div>
                                </v-card>                                
                            </v-col>
                        </v-row>
                    </div>
                    <div class="mt-40px mt-md-80px mt-lg-120px">
                        <v-card elevation="12" rounded>
                            <div class="pa-20px pa-md-30px px-lg-58px py-lg-60px">
                                <v-img data-aos="zoom-out" data-aos-delay="200" src="/images/sub/products/tissue/tissue-img4.svg" max-width="1480" :aspect-ratio="1480 / 508" contain class="w-100 mx-auto" />
                            </div>
                        </v-card>
                    </div>
                    <div class="mt-40px mt-md-80px mt-lg-120px">
                        <v-row no-gutters justify="end">
                            <v-col cols="auto">
                                <btn-primary to="/product/bone" size="xx-large">
                                    <v-img src="/images/bone-ci.svg" :height="$vuetify.breakpoint.mdAndUp ? '100%' : 32" :width="$vuetify.breakpoint.mdAndUp ? 350 : 210" :aspect-ratio="350 / 60" contain />
                                </btn-primary>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </div>
        </section>                          
    </products-layout>
</template>

<script>
import ProductsLayout from "@/components/client/templates/products-layout.vue";
import ProductsInfo from "@/components/client/sub/products-info.vue";
import ProductsInfoRow from "@/components/client/sub/products-info-row.vue";
import BtnPrimary from "@/components/dumb/btn-primary.vue";

export default {
    components: {
        ProductsLayout,
        ProductsInfo,
        ProductsInfoRow,
        BtnPrimary,
    },
    data() {
        return {
            info: [
                "Slim but Strong",
                "Simple Prosthetic Component",
                "Optimized immediate & early loading",
                "Easy to attain initial stability",
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
[data-aos][data-aos][data-aos-delay="7000"].aos-animate{
    transition-delay: 7s;
}
[data-aos][data-aos][data-aos-delay="7200"].aos-animate{
    transition-delay: 7.2s;
}
[data-aos][data-aos][data-aos-delay="7400"].aos-animate{
    transition-delay: 7.4s;
}
.container{
    max-width: calc(var(--container-lg) + var(--container-gutter)*2);
}
.tissue-section{
    &--1{
        position: relative;
        padding-bottom: 70px;
        &__bg{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 54%;
            z-index: 2;
        }
        &__inner{
            width: 100%;
            overflow: hidden;
        }
        &__row-title{
            margin: 40px 0 20px;
        }
        &__row-bg{
            position: relative;
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 100vw;
                left: 50%;
                bottom: var(--grid-gutter-sm);
                transform: translateX(-50%);
                height: calc(100% - 80px - (var(--grid-gutter-sm) * 2));
                background-color: var(--v-grey-lighten5);
            }
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 120px;
                height: 52px;
                background-image: url(/images/ci.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                right: var(--grid-gutter-sm);
                bottom: calc(var(--grid-gutter-sm) + 30px);
            }
        }
    }
    &--2{
        position: relative;
        padding: 30px 0 70px;
        &__bg{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            z-index: -1;
        }
    }
    &--3{
        padding: 70px 0;
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--v-grey-lighten5);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .tissue-section{
        &--1{
            padding-bottom: 110px;
            &__row{
                position: relative;
            }
            &__row-title{
                position: absolute;
                top: 200px;
                left: 0;
                z-index: 1;
                margin: 0;
            }
            &__row-bg{
                position: relative;
                &::before{
                    left: var(--grid-gutter-sm);
                    transform: translateX(0);
                    height: calc(100% - 100px - (var(--grid-gutter-sm) * 2));
                }
                &::after{
                    width: 214px;
                    height: 94px;
                    bottom: calc(var(--grid-gutter-sm) + 60px);
                }
            }
        }
        &--2{
            padding: 60px 0 110px;
        }
        &--3{
            padding: 110px 0;
        }
    }
}
@media (min-width:1024px){
    .tissue-section{
        &--1{
            padding-bottom: 140px;
            &__row-title{
                top: 258px;
            }
            &__row-bg{
                position: relative;
                &::before{
                    height: calc(100% - 138px - (var(--grid-gutter-sm) * 2));
                }
            }
        }
        &--2{
            padding: 60px 0 140px;
        }
        &--3{
            padding: 140px 0;
        }
    }
}
@media (min-width:1200px){
    .tissue-section{
        &--3{
            &__img{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }
            &::before{
                height: 1260px;
            }
        }
    }
}
</style>