var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('products-layout', {
    attrs: {
      "category": "tissue"
    }
  }, [_c('section', {
    staticClass: "tissue-section--1"
  }, [_c('v-img', {
    staticClass: "tissue-section--1__bg",
    attrs: {
      "src": "/images/sub/products/tissue/tissue-bg.svg",
      "max-width": 1040,
      "aspect-ratio": 1040 / 1360
    }
  }), _c('div', {
    staticClass: "tissue-section--1__inner"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "tissue-section--1__row row--sm"
  }, [_c('v-col', {
    staticClass: "tissue-section--1__row-title",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "tit tit--xl overflow-hidden"
  }, [_c('span', {
    staticClass: "d-block",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "7200"
    }
  }, [_vm._v("브라이트 임플란트")])]), _c('div', {
    staticClass: "overflow-hidden mt-12px mt-md-24px"
  }, [_c('v-img', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "7400",
      "src": "/images/tissue-ci.svg",
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? 556 : 398,
      "aspect-ratio": 556 / 84,
      "contain": ""
    }
  })], 1)]), _c('v-col', {
    staticClass: "tissue-section--1__row-bg",
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-md": "2"
    }
  }, [_c('v-img', {
    staticClass: "w-100 ml-auto",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "7000",
      "src": "/images/sub/products/tissue/tissue-img.png",
      "max-width": "1196",
      "aspect-ratio": 1196 / 1018
    }
  })], 1)], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "tissue-section--2"
  }, [_c('v-img', {
    staticClass: "tissue-section--2__bg",
    attrs: {
      "src": "/images/sub/products/tissue/tissue-bg2.jpg",
      "max-width": "1920",
      "aspect-ratio": 1920 / 1308
    }
  }), _c('div', {
    staticClass: "tissue-section--2__inner"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "ml-md-auto",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "200",
      "src": "/images/sub/products/tissue/tissue-img2.png",
      "max-width": "348",
      "aspect-ratio": 348 / 1138
    }
  })], 1), _c('v-col', {
    staticClass: "mt-30px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "pl-md-20px pl-lg-114px tit font-weight-bold break-keep",
    attrs: {
      "data-aos": "fade-right"
    }
  }, _vm._l(_vm.info, function (detail, index) {
    return _c('div', {
      key: index,
      class: index !== 0 ? 'mt-16px mt-md-32px' : ''
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" ·  ")]), _c('v-col', [_vm._v(" " + _vm._s(detail) + " ")])], 1)], 1);
  }), 0)])], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "tissue-section--3"
  }, [_c('div', {
    staticClass: "tissue-section--3__inner"
  }, [_c('v-container', [_c('v-card', {
    staticClass: "text-center",
    attrs: {
      "data-aos": "fade-up",
      "tile": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-28px"
  }, [_c('h3', {
    staticClass: "tit white--text line-height-1"
  }, [_vm._v("20º External Taper Fit")])])]), _c('div', {
    staticClass: "py-xl-40px mt-40px mt-md-80px"
  }, [_c('v-row', {
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "tissue-section--3__img mb-24px mb-md-56px mb-xl-0",
    attrs: {
      "cols": "12",
      "xl": "4"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "data-aos": "zoom-out",
      "src": "/images/sub/products/tissue/tissue-img3.png",
      "max-width": "430",
      "aspect-ratio": 430 / 514,
      "contain": ""
    }
  })], 1), _c('v-col', {
    staticClass: "text-md-right",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "data-aos": "fade-left",
      "tile": "",
      "color": "#f5eae9"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('div', {
    staticClass: "pr-xl-234px"
  }, [_c('h3', {
    staticClass: "tit line-height-1 red--text"
  }, [_vm._v(" External Hex ")]), _c('p', {
    staticClass: "font-size-20 font-size-md-26 mt-12px mt-md-24px"
  }, [_vm._v("- Easy to connect")])])])]), _c('v-card', {
    staticClass: "mt-30px mt-md-68px",
    attrs: {
      "data-aos": "fade-left",
      "data-aos-delay": "200",
      "tile": "",
      "color": "#f5eae9"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('div', {
    staticClass: "pr-xl-234px"
  }, [_c('h3', {
    staticClass: "tit line-height-1 red--text"
  }, [_vm._v(" Slim Nect Dimension ")]), _c('p', {
    staticClass: "font-size-20 font-size-md-26 mt-12px mt-md-24px"
  }, [_vm._v("- Maximal marginal bone protection")])])])])], 1), _c('v-col', {
    staticClass: "mt-14px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "data-aos": "fade-right",
      "data-aos-delay": "300",
      "tile": "",
      "color": "#f5eae9"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('div', {
    staticClass: "pl-xl-234px"
  }, [_c('h3', {
    staticClass: "tit line-height-1 red--text"
  }, [_vm._v(" Taper Fit ")]), _c('p', {
    staticClass: "font-size-20 font-size-md-26 mt-12px mt-md-24px"
  }, [_vm._v("- External Conical Seal Effect")]), _c('p', {
    staticClass: "font-size-20 font-size-md-26 mt-8px mt-md-16px"
  }, [_vm._v("- High Flexibility and Strength")]), _c('p', {
    staticClass: "font-size-20 font-size-md-26 mt-8px mt-md-16px"
  }, [_vm._v("- Easy Handling for Multiple Prosthetic")])])])])], 1)], 1)], 1), _c('div', {
    staticClass: "mt-40px mt-md-80px mt-lg-120px"
  }, [_c('v-card', {
    attrs: {
      "elevation": "12",
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px px-lg-58px py-lg-60px"
  }, [_c('v-img', {
    staticClass: "w-100 mx-auto",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "200",
      "src": "/images/sub/products/tissue/tissue-img4.svg",
      "max-width": "1480",
      "aspect-ratio": 1480 / 508,
      "contain": ""
    }
  })], 1)])], 1), _c('div', {
    staticClass: "mt-40px mt-md-80px mt-lg-120px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-primary', {
    attrs: {
      "to": "/product/bone",
      "size": "xx-large"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/bone-ci.svg",
      "height": _vm.$vuetify.breakpoint.mdAndUp ? '100%' : 32,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 350 : 210,
      "aspect-ratio": 350 / 60,
      "contain": ""
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }