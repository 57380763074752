<template>
    <products-layout category="bone">
        <section class="bone-section--1">
            <v-img src="/images/sub/products/bone/bone-bg.svg" :max-width="1040" :aspect-ratio="1040 / 1360" class="bone-section--1__bg" />
            <div class="bone-section--1__inner">
                <v-container>
                    <v-row class="bone-section--1__row row--sm">
                        <v-col cols="12" class="bone-section--1__row-title">
                            <h2 class="tit tit--xl overflow-hidden">
                                <span data-aos="fade-up" data-aos-delay="7200" class="d-block">브라이트 임플란트</span>
                            </h2>
                            <h2 class="overflow-hidden mt-12px mt-md-24px">
                                <v-img data-aos="fade-up" data-aos-delay="7400" src="/images/bone-ci.svg" :max-width="$vuetify.breakpoint.mdAndUp ? 496 : 354" :aspect-ratio="496 / 84" contain  />
                            </h2>
                        </v-col>
                        <v-col cols="12" md="10" offset-md="2" class="bone-section--1__row-bg">
                            <v-img data-aos="zoom-out" data-aos-delay="7000" src="/images/sub/products/bone/bone-img.png" max-width="1196" :aspect-ratio="1196 / 1018" class="w-100 ml-auto" />
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </section>
        <section class="bone-section--2">
            <v-img src="/images/sub/products/bone/bone-bg2.svg" max-width="1820" :aspect-ratio="1820 / 1704" class="bone-section--2__bg" />
            <div class="bone-section--2__inner">
                <v-container>
                    <v-row class="row--sm">
                        <v-col cols="12" md="6">
                            <v-img data-aos="zoom-out" data-aos-delay="200" src="/images/sub/products/bone/bone-img2.png" :aspect-ratio="790 / 1396" class="bone-section--2__img w-100 mx-auto" /> 
                        </v-col>
                        <v-col cols="12" md="5" offset-md="1" class="mt-30px mt-md-0">
                            <div data-aos="fade-left" class="tit font-weight-bold break-keep">
                                <div v-for="(detail, index) in info" :key="index" :class="index !== 0 ? 'mt-16px mt-md-32px' : ''">
                                    <v-row no-gutters>
                                        <v-col cols="auto">
                                            ·&nbsp;
                                        </v-col>
                                        <v-col>
                                            {{detail}}
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <v-card elevation="12" rounded class="mt-40px mt-md-80px mt-lg-120px">
                                <div class="pa-20px pa-md-30px px-lg-58px py-lg-60px">
                                    <v-img data-aos="zoom-out" data-aos-delay="200" src="/images/sub/products/bone/bone-img3.svg" max-width="544" :aspect-ratio="544 / 392" contain class="w-100 mx-auto" />
                                </div>
                            </v-card>
                            <v-card elevation="12" rounded class="mt-30px mt-md-60px">
                                <div class="pa-20px pa-md-30px px-lg-58px py-lg-60px">
                                    <v-img data-aos="zoom-out" data-aos-delay="200" src="/images/sub/products/bone/bone-img4.svg" max-width="544" :aspect-ratio="544 / 360" contain class="w-100 mx-auto" />
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </section>
        <section class="bone-section--3">
            <v-img src="/images/sub/products/tissue/tissue-bg3.svg" max-width="1756" :aspect-ratio="1756 / 944" class="bone-section--3__bg" />
            <div class="bone-section--3__inner">
                <v-container>
                    <v-row class="row--sm">
                        <v-col cols="12" md="5" style="position: relative">
                            <div class="bone-section--3__img-wrap">
                                <div class="bone-section--3__img__inner">
                                    <v-responsive :aspect-ratio="655 / 1000" class="d-none d-md-block"/>
                                    <v-img data-aos="zoom-out" data-aos-delay="200" src="/images/sub/products/tissue/tissue-img4.png" :aspect-ratio="1 / 1" contain class="bone-section--3__img"></v-img>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="7" class="mt-30px mt-md-0">
                            <div class="pl-md-20px pl-lg-120px ">
                                <products-info title="ø3.8 Single-Platform" class="text-right text-md-left">
                                    <p data-aos="fade-up" class="font-size-20 font-size-md-26">- Each fixture size fits the same platform size</p>
                                    <p data-aos="fade-up" class="font-size-20 font-size-md-26 mt-8px mt-md-16px">- Simplified prosthetic work flow</p>
                                </products-info>
                                <products-info title="Reduced Neck Dimension" class="mt-40px mt-md-80px">
                                    <p data-aos="fade-up" class="font-size-20 font-size-md-26">- Slim and Under-contoured</p>
                                    <p data-aos="fade-up" class="font-size-20 font-size-md-26 mt-8px mt-md-16px">- Maximal marginal bone protection</p>
                                </products-info>
                            </div>
                        </v-col>
                    </v-row>
                    <div class="mt-40px mt-md-80px mt-lg-120px">
                        <v-row no-gutters justify="end">
                            <v-col cols="auto">
                                <btn-primary to="/product/tissue" pointcolor="red" size="xx-large">
                                    <v-img src="/images/tissue-ci.svg" :height="$vuetify.breakpoint.mdAndUp ? '100%' : 32" :width="$vuetify.breakpoint.mdAndUp ? 394 : 210" :aspect-ratio="394 / 60" contain />
                                </btn-primary>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </div>
        </section>
    </products-layout>
</template>

<script>
import ProductsLayout from "@/components/client/templates/products-layout.vue";
import ProductsInfo from "@/components/client/sub/products-info.vue";
import ProductsInfoRow from "@/components/client/sub/products-info-row.vue";
import BtnPrimary from "@/components/dumb/btn-primary.vue";

export default {
    components: {
        ProductsLayout,
        ProductsInfo,
        ProductsInfoRow,
        BtnPrimary,
    },
    data() {
        return {
            info: [
                "Deep and Strong",
                "Excellent Joint Stability",
                "Simple Prosthetic Component",
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
[data-aos][data-aos][data-aos-delay="7000"].aos-animate{
    transition-delay: 7s;
}
[data-aos][data-aos][data-aos-delay="7200"].aos-animate{
    transition-delay: 7.2s;
}
[data-aos][data-aos][data-aos-delay="7400"].aos-animate{
    transition-delay: 7.4s;
}
.container{
    max-width: calc(var(--container-lg) + var(--container-gutter)*2);
}
.bone-section{
    &--1{
        position: relative;
        padding-bottom: 70px;
        &__bg{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 54%;
            z-index: 2;
        }
        &__inner{
            width: 100%;
            overflow: hidden;
        }
        &__row-title{
            margin: 40px 0 20px;
        }
        &__row-bg{
            position: relative;
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 100vw;
                left: 50%;
                bottom: var(--grid-gutter-sm);
                transform: translateX(-50%);
                height: calc(100% - 80px - (var(--grid-gutter-sm) * 2));
                background-color: var(--v-grey-lighten5);
            }
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 120px;
                height: 52px;
                background-image: url(/images/ci.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                right: var(--grid-gutter-sm);
                bottom: calc(var(--grid-gutter-sm) + 30px);
            }
        }
    }
    &--2{
        position: relative;
        padding: 30px 0 40px;
        &__bg{
            position: absolute;
            left: 0;
            top: 54%;
            width: 96%;
            z-index: -1;
        }
        &__img{
            max-width: 300px;
        }
    }
    &--3{
        overflow: hidden;
        position: relative;
        padding: 80px 0 70px;
        &__bg{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 92%;
            z-index: -1;
        }
        &__img{
            margin: 0 auto;
            max-width: 380px;
            width: 100%;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .bone-section{
        &--1{
            padding-bottom: 110px;
            &__row{
                position: relative;
            }
            &__row-title{
                position: absolute;
                top: 200px;
                left: 0;
                z-index: 1;
                margin: 0;
            }
            &__row-bg{
                position: relative;
                &::before{
                    left: var(--grid-gutter-sm);
                    transform: translateX(0);
                    height: calc(100% - 100px - (var(--grid-gutter-sm) * 2));
                }
                &::after{
                    width: 214px;
                    height: 94px;
                    bottom: calc(var(--grid-gutter-sm) + 60px);
                }
            }
        }
        &--2{
            padding: 60px 0 80px;
            &__img{
                max-width: 790px;
            }
        }
        &--3{
            padding: 120px 0 110px;
            &__img{
                &-wrap{
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: 0;
                }
                &__inner{
                    position: relative;
                    width: 100%;
                }
                margin: 0;
                position: absolute;
                max-width: unset;
                width: 1000px;
                right: 0;
                top: -96px;
                height: 100%;
                ::v-deep{
                    .v-image__image{
                        background-position: right center !important;
                    }
                }
            }
        }
    }
}
@media (min-width:1024px){
    .bone-section{
        &--1{
            padding-bottom: 140px;
            &__row-title{
                top: 258px;
            }
            &__row-bg{
                position: relative;
                &::before{
                    height: calc(100% - 138px - (var(--grid-gutter-sm) * 2));
                }
            }
        }
        &--2{
            padding: 60px 0 120px;
        }
        &--3{
            padding: 184px 0 264px;
            &__bg{
                bottom: -200px;
            }
        }
    }
}
@media (min-width:1200px){
}
</style>