var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('tit-wrap-dot', {
    attrs: {
      "title": "이용약관"
    }
  }), _c('terms-component', {
    attrs: {
      "code": "terms"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }