<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <tit-wrap-dot title="이용약관" />
            <terms-component code="terms"></terms-component>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapDot from "@/components/client/dumb/tit-wrap-dot.vue";
import TermsComponent from "@/components/client/terms/terms-component.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapDot,
        TermsComponent,
    },
};
</script>
