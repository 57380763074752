var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--client",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "PRODUCT"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--xxl"
  }, _vm._l(_vm.link, function (item) {
    return _c('v-col', {
      key: item.link,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-card', {
      class: 'product-card btn-card btn-card--' + item.color,
      attrs: {
        "to": item.link,
        "tile": "",
        "flat": "",
        "color": "transparent"
      }
    }, [_c('v-img', {
      staticClass: "product-card__img",
      attrs: {
        "src": item.image,
        "max-width": "560",
        "aspect-ratio": 560 / 662,
        "contain": ""
      }
    }), _c('v-responsive', {
      attrs: {
        "max-width": "560",
        "aspect-ratio": 560 / 200,
        "content-class": ""
      }
    }), _c('div', {
      staticClass: "product-card__contents pb-20px pb-md-30px pb-lg-60px"
    }, [_c('v-responsive', {
      staticClass: "w-100 mb-20px mb-md-30px mb-lg-74px",
      attrs: {
        "max-width": "560",
        "aspect-ratio": 560 / 382
      }
    }), _c('v-img', {
      staticClass: "w-100 mb-20px mb-md-32px",
      attrs: {
        "src": item.ciImage,
        "max-height": _vm.$vuetify.breakpoint.mdAndUp ? 44 : 32,
        "contain": ""
      }
    }), _c('btn-primary', {
      attrs: {
        "pointcolor": item.color
      }
    })], 1)], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }