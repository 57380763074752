<template>
    <div>
        <h2 data-aos="fade-up" class="tit tit--xl line-height-1">
            {{title}}
        </h2>
        <ul class="mt-20px mt-md-40px">
            <slot />
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "" },
    },
}
</script>

<style>

</style>