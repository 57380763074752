<template>
    <client-page class="app--client">
        <template #subHead>
            <sub-visual sh="PRODUCT" />
        </template>
        <page-section class="page-section--first page-section--last">
            <v-row class="row--xxl">
                <v-col v-for="item in link" :key="item.link" cols="12" md="6">
                    <v-card :to="item.link" tile flat color="transparent" :class="'product-card btn-card btn-card--'+item.color">
                        <v-img :src="item.image" max-width="560" :aspect-ratio="560 / 662" contain class="product-card__img"/>
                        <v-responsive max-width="560" :aspect-ratio="560 / 200" content-class=""/>
                        <div class="product-card__contents pb-20px pb-md-30px pb-lg-60px">
                            <v-responsive max-width="560" :aspect-ratio="560 / 382" class="w-100 mb-20px mb-md-30px mb-lg-74px"/>
                            <v-img :src="item.ciImage" :max-height="$vuetify.breakpoint.mdAndUp ? 44 : 32" contain class="w-100 mb-20px mb-md-32px" />
                            <btn-primary :pointcolor="item.color" />
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import BtnPrimary from "@/components/dumb/btn-primary.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        SubVisual,
        BtnPrimary,
    },
    data() {
        return {
            link : [
                {
                    link : "/product/tissue",
                    image : "/images/sub/products/products-img.png",
                    ciImage : "/images/tissue-ci.svg",
                    color : "red",
                },
                {
                    link : "/product/bone",
                    image : "/images/sub/products/products-img2.png",
                    ciImage : "/images/bone-ci.svg"
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep{
    .product-card{
        overflow: visible;
        position: relative;
        &__img{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            transition: 0.25s ease-out;
        }
        &__contents{
            display: flex;
            flex-direction: column;
            align-items: center;
            background-image: url(/images/sub/products/product-card-bg.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &::before,
        .v-ripple__container{
            opacity: 0 !important;
            display: none !important;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    ::v-deep{
        .product-card:hover{
            .product-card__img{
                transform: translateX(-50%) translateY(19%) scale(.7);
            }
        }
    }
}
@media (min-width:1200px){
}

</style>