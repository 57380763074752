import { render, staticRenderFns } from "./ProductTissuePage.vue?vue&type=template&id=6ef4db6a&scoped=true&"
import script from "./ProductTissuePage.vue?vue&type=script&lang=js&"
export * from "./ProductTissuePage.vue?vue&type=script&lang=js&"
import style0 from "./ProductTissuePage.vue?vue&type=style&index=0&id=6ef4db6a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef4db6a",
  null
  
)

export default component.exports