var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--client",
    scopedSlots: _vm._u([{
      key: "contentsImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "fixed-area"
        }, [_c('div', {
          staticClass: "fixed-area__inner"
        }, [_c('div', {
          staticClass: "fixed-area__bg"
        }), _c('div', {
          staticClass: "fixed-area__ani"
        }, [_c('v-img', {
          staticClass: "fixed-area__ani-img fixed-area__ani-img--2",
          attrs: {
            "src": '/images/sub/products/' + _vm.category + '/text-ani2.svg',
            "contain": ""
          }
        }), _c('v-img', {
          staticClass: "fixed-area__ani-img fixed-area__ani-img--3",
          attrs: {
            "src": '/images/sub/products/' + _vm.category + '/text-ani3.svg',
            "contain": ""
          }
        }), _c('v-img', {
          staticClass: "fixed-area__ani-img fixed-area__ani-img--1",
          attrs: {
            "src": '/images/sub/products/' + _vm.category + '/text-ani.svg',
            "contain": ""
          }
        }), _c('v-img', {
          staticClass: "fixed-area__ani-img fixed-area__ani-img--4",
          attrs: {
            "src": '/images/sub/products/' + _vm.category + '/text-ani4.svg',
            "contain": ""
          }
        })], 1)])]), _c('div', {
          staticClass: "product-contents"
        }, [_vm._t("default")], 2)];
      },
      proxy: true
    }], null, true)
  });

}
var staticRenderFns = []

export { render, staticRenderFns }