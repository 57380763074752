var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('products-layout', {
    attrs: {
      "category": "bone"
    }
  }, [_c('section', {
    staticClass: "bone-section--1"
  }, [_c('v-img', {
    staticClass: "bone-section--1__bg",
    attrs: {
      "src": "/images/sub/products/bone/bone-bg.svg",
      "max-width": 1040,
      "aspect-ratio": 1040 / 1360
    }
  }), _c('div', {
    staticClass: "bone-section--1__inner"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "bone-section--1__row row--sm"
  }, [_c('v-col', {
    staticClass: "bone-section--1__row-title",
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "tit tit--xl overflow-hidden"
  }, [_c('span', {
    staticClass: "d-block",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "7200"
    }
  }, [_vm._v("브라이트 임플란트")])]), _c('h2', {
    staticClass: "overflow-hidden mt-12px mt-md-24px"
  }, [_c('v-img', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "7400",
      "src": "/images/bone-ci.svg",
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? 496 : 354,
      "aspect-ratio": 496 / 84,
      "contain": ""
    }
  })], 1)]), _c('v-col', {
    staticClass: "bone-section--1__row-bg",
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-md": "2"
    }
  }, [_c('v-img', {
    staticClass: "w-100 ml-auto",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "7000",
      "src": "/images/sub/products/bone/bone-img.png",
      "max-width": "1196",
      "aspect-ratio": 1196 / 1018
    }
  })], 1)], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "bone-section--2"
  }, [_c('v-img', {
    staticClass: "bone-section--2__bg",
    attrs: {
      "src": "/images/sub/products/bone/bone-bg2.svg",
      "max-width": "1820",
      "aspect-ratio": 1820 / 1704
    }
  }), _c('div', {
    staticClass: "bone-section--2__inner"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "bone-section--2__img w-100 mx-auto",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "200",
      "src": "/images/sub/products/bone/bone-img2.png",
      "aspect-ratio": 790 / 1396
    }
  })], 1), _c('v-col', {
    staticClass: "mt-30px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "5",
      "offset-md": "1"
    }
  }, [_c('div', {
    staticClass: "tit font-weight-bold break-keep",
    attrs: {
      "data-aos": "fade-left"
    }
  }, _vm._l(_vm.info, function (detail, index) {
    return _c('div', {
      key: index,
      class: index !== 0 ? 'mt-16px mt-md-32px' : ''
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" ·  ")]), _c('v-col', [_vm._v(" " + _vm._s(detail) + " ")])], 1)], 1);
  }), 0), _c('v-card', {
    staticClass: "mt-40px mt-md-80px mt-lg-120px",
    attrs: {
      "elevation": "12",
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px px-lg-58px py-lg-60px"
  }, [_c('v-img', {
    staticClass: "w-100 mx-auto",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "200",
      "src": "/images/sub/products/bone/bone-img3.svg",
      "max-width": "544",
      "aspect-ratio": 544 / 392,
      "contain": ""
    }
  })], 1)]), _c('v-card', {
    staticClass: "mt-30px mt-md-60px",
    attrs: {
      "elevation": "12",
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px px-lg-58px py-lg-60px"
  }, [_c('v-img', {
    staticClass: "w-100 mx-auto",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "200",
      "src": "/images/sub/products/bone/bone-img4.svg",
      "max-width": "544",
      "aspect-ratio": 544 / 360,
      "contain": ""
    }
  })], 1)])], 1)], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "bone-section--3"
  }, [_c('v-img', {
    staticClass: "bone-section--3__bg",
    attrs: {
      "src": "/images/sub/products/tissue/tissue-bg3.svg",
      "max-width": "1756",
      "aspect-ratio": 1756 / 944
    }
  }), _c('div', {
    staticClass: "bone-section--3__inner"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "bone-section--3__img-wrap"
  }, [_c('div', {
    staticClass: "bone-section--3__img__inner"
  }, [_c('v-responsive', {
    staticClass: "d-none d-md-block",
    attrs: {
      "aspect-ratio": 655 / 1000
    }
  }), _c('v-img', {
    staticClass: "bone-section--3__img",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "200",
      "src": "/images/sub/products/tissue/tissue-img4.png",
      "aspect-ratio": 1 / 1,
      "contain": ""
    }
  })], 1)])]), _c('v-col', {
    staticClass: "mt-30px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "pl-md-20px pl-lg-120px"
  }, [_c('products-info', {
    staticClass: "text-right text-md-left",
    attrs: {
      "title": "ø3.8 Single-Platform"
    }
  }, [_c('p', {
    staticClass: "font-size-20 font-size-md-26",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("- Each fixture size fits the same platform size")]), _c('p', {
    staticClass: "font-size-20 font-size-md-26 mt-8px mt-md-16px",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("- Simplified prosthetic work flow")])]), _c('products-info', {
    staticClass: "mt-40px mt-md-80px",
    attrs: {
      "title": "Reduced Neck Dimension"
    }
  }, [_c('p', {
    staticClass: "font-size-20 font-size-md-26",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("- Slim and Under-contoured")]), _c('p', {
    staticClass: "font-size-20 font-size-md-26 mt-8px mt-md-16px",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v("- Maximal marginal bone protection")])])], 1)])], 1), _c('div', {
    staticClass: "mt-40px mt-md-80px mt-lg-120px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-primary', {
    attrs: {
      "to": "/product/tissue",
      "pointcolor": "red",
      "size": "xx-large"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/tissue-ci.svg",
      "height": _vm.$vuetify.breakpoint.mdAndUp ? '100%' : 32,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 394 : 210,
      "aspect-ratio": 394 / 60,
      "contain": ""
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }