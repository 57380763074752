var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', {
    staticClass: "tit tit--xl line-height-1",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('ul', {
    staticClass: "mt-20px mt-md-40px"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }